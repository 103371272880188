<template>
<div class="main">
	<h1>Oops!</h1>
	<p>We apologize, our website is currently undergoing maintenance.</p>
	<p>Please try again later.</p>
</div>
</template>

<script>
export default {
	name: 'maintenance-mode',
	data() {
		return {
		}
	},
	methods: {
	}
}
</script>

<style lang="scss" scoped>
.main {
	text-align: center;
	font-family: Helvetica, Arial, sans-serif;
}
</style>
